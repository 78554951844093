import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

const parseTimeToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

const formatSecondsToTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const calculateAveragesByGroup = (dataSource, filterSummary) => {
  const groupData = {};

  dataSource.forEach(item => {
    const groupKey = item[filterSummary]; // Use filterSummary prop dynamically

    // Check if groupKey is defined
    if (!groupKey) {
      console.warn(`Undefined group key for item:`, item);
      return; // Skip this iteration if groupKey is undefined
    }

    const { TotalLoggedHours, TotalIdleHours, TotalProductiveHours, TOTAL_ON_SYSTEM, AwayFromSystem } = item;

    if (!groupData[groupKey]) {
      groupData[groupKey] = {
        totalLoggedSeconds: 0,
        totalIdleSeconds: 0,
        totalProductiveSeconds: 0,
        totalOnSystemSeconds: 0,
        totalAwaySeconds: 0,
        count: 0,
      };
    }

    groupData[groupKey].totalLoggedSeconds += parseTimeToSeconds(TotalLoggedHours);
    groupData[groupKey].totalIdleSeconds += parseTimeToSeconds(TotalIdleHours);
    groupData[groupKey].totalProductiveSeconds += parseTimeToSeconds(TotalProductiveHours);
    groupData[groupKey].totalOnSystemSeconds += parseTimeToSeconds(TOTAL_ON_SYSTEM);
    groupData[groupKey].totalAwaySeconds += parseTimeToSeconds(AwayFromSystem);
    groupData[groupKey].count += 1;
  });

  const averages = {
    AverageTotalLoggedHours: {},
    AverageTotalIdleHours: {},
    AverageTotalProductiveHours: {},
    AverageTotalOnSystem: {},
    AverageAwayFromSystem: {},
  };

  Object.keys(groupData).forEach((key) => {
    const { 
      totalLoggedSeconds, 
      totalIdleSeconds, 
      totalProductiveSeconds, 
      totalOnSystemSeconds, 
      totalAwaySeconds, 
      count 
    } = groupData[key];

    averages.AverageTotalLoggedHours[key] = formatSecondsToTime(totalLoggedSeconds / count);
    averages.AverageTotalIdleHours[key] = formatSecondsToTime(totalIdleSeconds / count);
    averages.AverageTotalProductiveHours[key] = formatSecondsToTime(totalProductiveSeconds / count);
    averages.AverageTotalOnSystem[key] = formatSecondsToTime(totalOnSystemSeconds / count);
    averages.AverageAwayFromSystem[key] = formatSecondsToTime(totalAwaySeconds / count);
  });

  return averages;
};


const DashboardGroupBarChart = ({ filteredData, filterSummary }) => {
  const dataSource = Array.isArray(filteredData.data) ? filteredData.data : [];
  const entryCount = dataSource.length;
  const averages = calculateAveragesByGroup(dataSource, filterSummary);
console.log(dataSource);
  // Prepare data for the chart
  const groups = Object.keys(averages.AverageTotalLoggedHours);
  const series = groups.map(group => ({
    name: group,
    data: [
      parseTimeToSeconds(averages.AverageTotalLoggedHours[group]),
      parseTimeToSeconds(averages.AverageTotalIdleHours[group]),
      parseTimeToSeconds(averages.AverageTotalProductiveHours[group]),
      parseTimeToSeconds(averages.AverageTotalOnSystem[group]),
      parseTimeToSeconds(averages.AverageAwayFromSystem[group]),
    ],
  }));

  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'LoggedHours',
    'IdleHours',
    'ProductiveHours',
    'OnSystem',
    'breaks',
      ],
    },
    yaxis: {
      title: {
        text: 'Time (seconds)',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return formatSecondsToTime(val);
        },
      },
    },
  };

  return (
    <div>
      {/*<h5>Dashboard Group Bar Chart</h5>
      <div>
        <h6>Filtered Data Count: {entryCount}</h6>
      </div>
      <div>
        <h6>Average Times by {filterSummary} (JSON Format):</h6>
        <pre>{JSON.stringify(averages, null, 2)}</pre>
      </div>*/}
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
    </div>
  );
};

DashboardGroupBarChart.propTypes = {
  filteredData: PropTypes.shape({
    data: PropTypes.array.isRequired,
  }).isRequired,
  filterSummary: PropTypes.oneOf(['Team', 'Department']).isRequired, // Ensure it's either Team or Department
};

export default DashboardGroupBarChart;
