import React, { useState } from 'react';
import { Select, Button } from 'antd';
import moment from 'moment';
import ApexChart2 from './Monthapexchart';
import '../Css/Page1.css';
import { API_BASE_URL1, API_BASE_URL2, API_BASE_URL_LOCAL_HOST } from '../../config';

const { Option } = Select;

const MonthviewTimeline = ({ options }) => {
    const [selectedId, setSelectedId] = useState('');
    const [filteredId, setFilteredId] = useState('');
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [fetchedData, setFetchedData] = useState(null); // State to hold fetched data
    const [showChart, setShowChart] = useState(false); // State to control chart visibility

    // Check if options and EMPID are defined
    if (!options || !Array.isArray(options.EMPID) || options.EMPID.length === 0) {
        return <div>No employee IDs available.</div>;
    }

    const employeeIds = options.EMPID.map(emp => emp.value);

    // Month names for display
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const currentYear = moment().year();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - i); // Last 10 years

    const handleChange = (value) => {
        setSelectedId(value);
    };

    const handleFilter = async () => {
        if (selectedId && selectedMonth && selectedYear) {
            setFilteredId(selectedId);

            // Create start and end dates using moment
            const startDate = moment(`${selectedYear}-${selectedMonth}-01`).format('YYYY-MM-DD'); // First day of the month
            const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD'); // Last day of the month

            console.log(`Sending data: Employee ID: ${selectedId}, Start Date: ${startDate}, End Date: ${endDate}`);

            try {
                const response = await fetch(`${API_BASE_URL1}/api/xampp.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        EMPID: selectedId,
                        date: startDate,
                        enddate: endDate,
                        userid: userid, // Send userid here
                    }),
                });

                const data = await response.json();
                console.log('Response from server:', data);
                setFetchedData(data); // Update state with fetched data
                setShowChart(true); // Show chart on successful data fetch
            } catch (error) {
                console.error('Error sending data:', error);
            }
        } else {
            console.log('Please select an employee ID, month, and year to filter.');
        }
    };

    const handleReset = () => {
        // Reset the state variables
        setSelectedId('');           // Clear selected employee ID
        setFilteredId('');           // Clear filtered employee ID
        setSelectedMonth(null);      // Clear selected month
        setSelectedYear(null);       // Clear selected year
        setFetchedData(null);        // Clear the fetched data
        setShowChart(false);         // Hide the chart on reset

        console.log('Filters and chart data have been reset.');
    };
    const empid = localStorage.getItem('empid') || '';
    const uempid = empid.split(',').map(emp => emp.trim());
    const userid = localStorage.getItem('EMPID');


    return (
        <div>
            <div className="card mt-2 mb-2">
                <div className="card-header">
                    <h5>Select an Employee ID:</h5>
                </div>

                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12"></div>

                        {/* Employee ID Select */}
                        <Select
                            value={selectedId || filteredId || undefined}
                            onChange={handleChange}
                            style={{ width: "200px", fontSize: "10px", padding: "2px 6px" }}
                            dropdownStyle={{ width: "150px" }}
                            placeholder={<span className="bold-placeholder">SELECT EMPID</span>}
                        >
                            {uempid.map(emp => (
                          <Option key={emp} value={emp}>
                            {emp}
                          </Option>
                        ))}
                        </Select>

                        {/* Month Select */}
                        <Select
                            value={selectedMonth || undefined}
                            onChange={setSelectedMonth}
                            style={{ width: "200px", fontSize: "10px", padding: "2px 6px" }}
                            dropdownStyle={{ width: "150px" }}
                            placeholder={<span className="bold-placeholder">SELECT MONTH</span>}
                        >
                            {monthNames.map((month, index) => (
                                <Option key={index + 1} value={index + 1}>
                                    {month} {/* Display month names */}
                                </Option>
                            ))}
                        </Select>

                        {/* Year Select */}
                        <Select
                            value={selectedYear || undefined}
                            onChange={setSelectedYear}
                            style={{ width: "200px", fontSize: "10px", padding: "2px 6px" }}
                            dropdownStyle={{ width: "150px" }}
                            placeholder={<span className="bold-placeholder">SELECT YEAR</span>}
                        >
                            {years.map(year => (
                                <Option key={year} value={year}>
                                    {year}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div className="col-sm-3 mt-2">
                        <Button type="primary" size="small" onClick={handleFilter} style={{ marginRight: 10 }}>
                            Filter
                        </Button>
                        <Button onClick={handleReset} size="small">
                            Reset
                        </Button>
                    </div>

                    {/* Conditionally render the chart */}
                    {showChart && <ApexChart2 data={fetchedData} />}
                </div>
            </div>
        </div>
    );
};

export default MonthviewTimeline; 
